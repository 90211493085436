@font-face {
    font-family: 'Lauer';
    src: url('lauer-regular-webfont.woff2') format('woff2'), url('lauer-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html.noscroll {
    position: fixed;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    &.overflow-hidden {
        overflow:hidden;
    }
}

video {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    cursor: auto;
}

/* Add some content at the bottom of the video/page */

.container {
    height: 100vh;
    width: 100%;
    padding: 0;
    margin: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    flex-direction: column;
}

.lauerbutton {
    font-family: 'Lauer', 'Helvetica', 'Arial', sans-serif;
    font-size: 3vh;
    text-align: center;
    cursor: pointer;
    color: black;
    background-color: #E5E5E5;
    border-radius: 2rem;
    border: 2px solid black;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    -webkit-transition: -webkit-transform 0.5s ease;
    -ms-transition: -ms-transform 0.5s ease;
    transition: transform 0.5s ease;
    height: auto;
    margin: auto;
    position: relative;
    z-index: 9;
    will-change: transform;
}

.lauertainer {
    font-family: 'Lauer', 'Helvetica', 'Arial', sans-serif;
    font-size: 3vh;
    text-align: center;
    cursor: pointer;
    color: transparent;
    background-color: transparent;
    border-radius: 2em;
    height: auto;
    margin: auto;
    position: relative;
    z-index: 10;
}

.lauertainer:hover>.lauerbutton {
    transform: rotate(540deg);
    -ms-transform: rotate(540deg);
    -webkit-transform: rotate(540deg);
}

.mirror {
    -webkit-transform: scaleY(-1);
    transform: scaleY(-1);
    display: inline-block;
}

a:visited {
    color: inherit;
}

a:link {
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width: 400px) {
    .lauerbutton {
  display:none;
    }

    .lauertainer {
  display:none;
    }
}